import React, { useState } from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import SingleRecentPost from "../Elements/SingleRecentPost";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { apiBaseUrl } from "../../config";
export default function GyanBlogSideBar({ isMba = false, recentPost, categoryList, postid}) {
    const [formData, setFormData] = useState({
      blog_id: "",
      name: "",
      email: "",
      state: "",
      phone: "",
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };

   const handleSubmit = async (e) => {
      e.preventDefault();
  
      const apiUrl = `${apiBaseUrl}submit-blog-expert-enquiry`;
  
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            blog_id: postid,
            name: formData.name,
            email: formData.email,
            state: formData.state,
            phone: formData.phone,
          }),
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const result = await response.json();
        toast.success("Form submitted successfully!");
  
        setFormData({ name: "", email: "", state: "", phone: "" });
      } catch (error) {
        console.error("Error submitting the form:", error);
        toast.error("Failed to submit the form. Please try again later.");
      }
    };

  return (
    <>
     <ToastContainer />
     <div className={`${Style.sidebar} sidebar`}>
      {/* Gallery */}
      <div className={Style.sidebar_section}>
        <div className={Style.sidebar_section_title}>Talk to Our Experts</div>
        <div className={Style["sidebar_talk-expert"]}>
          <form method="POST" className="comment_form" onSubmit={handleSubmit}>
            <div className="input_col">
              <div className="form_title">Name*</div>
              <input
                type="text"
                name="name"
                className="comment_input"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input_col">
              <div className="form_title">Email*</div>
              <input
                type="text"
                name="email"
                className="comment_input"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="input_col">
              <div className="form_title">State*</div>
              <input
                 type="text"
                 name="state"
                 className="comment_input"
                 value={formData.state}
                 onChange={handleChange}
                 required
              />
            </div>
            <div className="input_col">
              <div className="form_title">Phone No*</div>
              <input
                 type="text"
                 name="phone"
                 className="comment_input"
                 value={formData.phone}
                 inputMode="numeric"
                 pattern="[0-9]*"
                 onChange={(e) =>
                   setFormData({
                     ...formData,
                     phone: e.target.value.replace(/[^0-9]/g, ""),
                   })
                 }
                 required
              />
            </div>
            <div>
              <button type="submit" className="comment_button trans_200">
                submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* Tags */}
      <div
        className="sidebar_section "
        style={isMba === true ? { display: "block" } : { display: "none" }}
      >
        <div className="sidebar_image">
          <img className="img-fluid" src="/image/ads/side add 3.webp" alt="" />
        </div>
      </div>
    </div>
    </>
    
  );
}
