import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import { setExpertAdvice } from "../reducer/expertAdviceSlice";
import OwlCarousel from "react-owl-carousel";
import { responsiveCarousel } from "../utils/constants";

export default function ExpertAdvice({ getModal = "#modalLoginForm" }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const expertAdviceList = useSelector(
    (state) => state.expertAdvice?.expertAdviceList
  );

  const fetchExpertAdvice = () => {
    setLoading(true);
    fetch(`${apiBaseUrl}career-expert-review`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.status === "success") {
          // setUniversity(data);
          dispatch(setExpertAdvice(data && data.career_expert_review));
        } else {
          console.error("Invalid response format", data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  // useEffect(() => {
  //   document.addEventListener("DOMContentLoaded", setRating);
  // }, []);
  // function setRating(rating, identity) {
  //   const totalStar = 5;
  //   const starPercentage = `${(Number(rating) / totalStar) * 100}%`;
  //   //round to nearest 10
  //   // const starPercentageRounded = `${
  //   //   Math.round(starPercentage / 10) * 10
  //   // }%`;

  //   //set with of stars inner to percentage
  //   document.querySelector(`#${identity} .stars-inner`).style.width =
  //     starPercentage;
  //   // return (
  //   //   <>
  //   //     <div className=stars-outer">
  //   //       <div className=stars-inner"></div>
  //   //     </div>
  //   //   </>
  //   // );
  // }

  useEffect(() => {
    fetchExpertAdvice();
  }, []);

  return (
    <>
      <div
        id="Expert_Advice"
        className="Testimonials-wrap background-dark section"
      >
        <div className="container" data-aos="fade-in">
          <div className="row">
            <div className="col">
              <div className="text-left">
                <h2 className="section_title">
                  Get Real advice on your career from experts
                </h2>
              </div>
            </div>
          </div>
          <div className="row testimonials gtco-testimonials mt-lg-4 mt-0">
            {/* TESTIMONIALS */}
            <div className="col-sm-12">
              {loading ? (
                <h3>Loading...</h3>
              ) : (
                <OwlCarousel
                  center
                  loop
                  autoplay
                  smartSpeed={450}
                  responsive={responsiveCarousel}
                  id="customers-testimonials"
                  className="owl-carousel "
                >
                  {expertAdviceList &&
                    expertAdviceList.map((item, index) => (
                      <div
                        key={item.name + index}
                        id={"rating" + index}
                        className="item testi_col"
                      >
                        <div className="shadow-effect">
                          <img
                            className="img-circle"
                            src={`${baseUrl}${item.image}`}
                            alt=""
                          />
                          <p>{item.name}</p>
                          <div className="quote-content" on>
                            {/* <span className="fa fa-star checked" />
                            <span className="fa fa-star checked" />
                            <span className="fa fa-star checked" />
                            <span className="fa fa-star checked" />
                            <span className="fa fa-star" /> */}
                            <div className="stars-outer">
                              <div
                                className="stars-inner"
                                style={{ width: `${(item.rating / 5) * 100}%` }}
                              ></div>
                            </div>
                            {/* <span className=number-rating"></span> */}
                          </div>
                        </div>
                        <div className="testimonial-name">
                          <div
                            className="getConsultModalOpen"
                            data-toggle="modal"
                            data-target={getModal}
                          >
                            Consult Now
                          </div>
                          <span className="mid-line" />
                          <Link to={`/expert/${item.id}`} className="testi-know-more">
                            Know more
                          </Link>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              )}
            </div>
            {/* END OF TESTIMONIALS */}
          </div>
        </div>
      </div>
    </>
  );
}
