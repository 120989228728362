import React from "react";
import Style from "../../assets/styles/ModuleStyle/course.module.css";
import { Link } from "react-router-dom";
import { baseUrl } from "../../config";
import Parser from "../Elements/Parser";

export default function BlogDetails({ blogDetails }) {
  const title = blogDetails?.title || "Untitled Blog"; 
  const shareUrl = `${window.location.origin}/blog-details/${encodeURIComponent(title)}`;
  const shareText = encodeURIComponent(title);

  return (
    <>
      <div className={Style.blog_content}>
        <div className={Style.blog_title}>{title}</div>
        <div className={Style.blog_meta}>
          <ul>
            <li>
              Post on <Link to="#">{blogDetails?.date_time || "Unknown Date"}</Link>
            </li>
            <li>
              By <Link to="#">{blogDetails?.published_by || "Unknown Author"}</Link>
            </li>
          </ul>
        </div>
        <div className={Style.blog_image}>
          <img
            src={blogDetails?.image ? `${baseUrl}${blogDetails.image}` : "/default-image.jpg"}
            alt={title}
          />
        </div>
        <Parser content={blogDetails?.content || "No content available."} />
      </div>
      <div
        className={`${Style.blog_extra} mb-5 d-flex flex-lg-row flex-column align-items-lg-center align-items-start justify-content-start`}
      >
        <div className={Style.blog_tags}>
          <span>Tags: </span>
          <ul>
            <li>
              <Link to="#">{blogDetails?.published_by || "No Tags"}</Link>
            </li>
          </ul>
        </div>
        <div className={`${Style.blog_social} ml-lg-auto`}>
          <span>Share: </span>
          <ul>
            <li>
              <a
                href={`https://api.whatsapp.com/send?text=${shareText}%20${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on WhatsApp"
              >
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Facebook"
              >
                <i className="fa fa-facebook" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Twitter"
              >
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
            </li>
            <li>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareText}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on LinkedIn"
              >
                <i className="fa fa-linkedin" aria-hidden="true" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
