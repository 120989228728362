import React, { useState } from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { setModalDynamicImg } from "../../reducer/universitySlice";
import { useDispatch } from "react-redux";
import ModalImg from "../Modals/ModalImg";
import ModalImgtwo from "../Modals/ModalImgtwo";

export default function CourseFee({
  heading = "Course Fee ",
  courseList,
  totalfees,
  courseName,
  specializaion,
  uniName,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalImgSrc, setModalImgSrc] = useState(null);

  const handeladdtocompare = (university_id) => {
    navigate(`/comparison/${university_id}`);
  };

  return (
    <div id="Compare" className="uni_program_wrapper">
      <div className="title mt-5">
        <h3>{heading}</h3>
      </div>
      <div className="program_university mt-3">
        <div className="container">
          <div className={`row row--10 ${CrStyle["contact-university-now"]}`}>
            <div className={`row row--10 ${CrStyle["headingss"]}`}>
              <div
                className={`col-md-4 ${CrStyle.poffer} ${CrStyle.pofferone}`}
              >
                <span>
                  <strong>Course</strong>
                </span>
              </div>
              <div
                className={`col-md-4 ${CrStyle.poffer} ${CrStyle.poffertwo}`}
              >
                <span>
                  <strong>Total Fee</strong>
                </span>
              </div>
              <div
                className={`col-md-4 ${CrStyle.poffer} ${CrStyle.pofferthree} ${CrStyle["full-width"]}`}
              >
                <span>
                  <strong>Compare</strong>
                </span>
              </div>
            </div>
            {courseList?.map((item, index) => (
              <>
                <div key={index} className={`row row--10 ${CrStyle.contentss}`}>
                  <div
                    className={`col-md-4 ${CrStyle.poffer} ${CrStyle.pofferone}`}
                  >
                    <Link
                      to={`/course/${uniName}/${item?.coursename}`}
                      className=""
                    >
                      <span>{item.coursename}</span>
                    </Link>
                    <span>{item.specialization}</span>
                  </div>
                  <div className="col-md-4 col-6 text-lg-start text-sm-end poffer poffertwo">
                    <span>
                      ₹{" "}
                      {item?.totalfees
                        ? String(item.totalfees)
                            .replace(/â‚¹/g, "")
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : "N/A"}
                    </span>
                  </div>

                  <div
                    className={`col-md-4 poffer ${CrStyle.pofferthree} full-width zoom-gallery`}
                  >
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => handeladdtocompare(item?.university_id)}
                    >
                      Add to Compare
                    </p>
                    <Link
                      to={item.completefees}
                      data-toggle="modal"
                      data-target="#modalLoginImgtwo"
                      className="gyan-link popup-zoom"
                      onClick={() => {
                        setModalImgSrc(item?.completefees);
                      }}
                    >
                      View Fee Breakup
                    </Link>
                    /
                    <Link
                      to
                      className="gyan-link"
                      data-toggle="modal"
                      data-target="#modalLoginForm2"
                    >
                      Get Offer
                    </Link>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <ModalImgtwo imgSr={modalImgSrc} />
    </div>
  );
}
