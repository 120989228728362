import React, { useContext, useEffect, useState } from "react";
import CrStyle from "../../assets/styles/ModuleStyle/course.module.css";
import owlStyle from "../../assets/styles/ModuleStyle/customOwl.module.css";
import OwlCarousel from "react-owl-carousel";
import AppContext from "../../Context/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { setModalDynamicImg } from "../../reducer/universitySlice";
import { baseUrl } from "../../config";
import { Link, useParams } from "react-router-dom";
import ModalImg from "../Modals/ModalImg";

export default function JulyOfferWithCarousel({
  details,
  isSingleImg = false,
  imgSrc,
  banner,
  universityname,
}) {
  useEffect(() => {
  }, [banner]);


  const { isMobile } = useContext(AppContext);
  const dispatch = useDispatch();
  const mainUniversity = useSelector((state) => state.mainuniversity?.university);
  const ustate = mainUniversity?.univercity_details?.state;
  const universityId = mainUniversity?.univercity_details?.id;

  console.log("details", imgSrc);

  const completefees =
    details?.complete_fees === "" ||
      details?.complete_fees === undefined ||
      details?.complete_fees === null
      ? ""
      : `${baseUrl}${details?.complete_fees}`;




  return (
    <>
      <div id="overview" className="University_details" style={{ marginTop: "40px" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="university_banner_wrap"
                style={{ height: isMobile ? "300px" : "100%" }}
              >
                {/* Home Slider */}
                {isSingleImg === true ? (
                  <img width={"100%"} src={`${baseUrl}${imgSrc}`} alt="" />
                ) : (
                  <>
                    <OwlCarousel
                      className={`owl-carousel owl-theme university_slider`}
                      loop
                      items={1}
                      nav
                      dots={false}
                      navText={[
                        "<i class='fa fa-chevron-left'></i>",
                        "<i class='fa fa-chevron-right'></i>",
                      ]}
                      navContainerClass={owlStyle.containerNav}
                      navClass={["navPrev", "navNext"]}
                    >
                      {banner?.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="home_slider_background">
                            <img src={`${baseUrl}${item.image}`} alt={`slide-${index}`} />
                          </div>
                        </div>
                      ))
                      }
                      {/* Home Slider Item */}
                    </OwlCarousel>

                    <div className={CrStyle["university_video_main"]}>
                      {/*	<p>video</p>*/}
                      <Link to={`/university/videodetails/${universityId}`}>
                        <i className="uil uil-play-circle" />
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className={`uni_state_details ${CrStyle.university_education} d-flex flex-row align-items-center justify-content-around`}
              >
                <div
                  className={`${CrStyle.university_location} ${CrStyle["border-right"]}`}
                >
                  <p>Educational Model</p>
                  <h4>{details?.education_model}</h4>
                </div>
                <div className={CrStyle.university_location}>
                  <p>State</p>
                  <h4>{ustate && ustate}</h4>
                </div>
              </div>
              <div
                className={`uni_state_details ${CrStyle.university_education} d-flex flex-row align-items-center justify-content-around`}
              >
                <div
                  className={`${CrStyle.university_location} fees_amt  ${CrStyle["border-right"]} border-right`}
                  style={{ height: "34px" }}
                >
                  {/* <h3 className={CrStyle["btn-shine"]}>Rs 17500 / SEM</h3> */}
                  <h3 className={CrStyle["btn-shine"]}>₹ {details?.fees.replace(/â‚¹/g, "")}</h3>
                </div>
                <div
                  className={`${CrStyle["university_location"]} ${CrStyle["view_fees_popup"]}`}
                >
                  <p style={{ cursor: "pointer" }}>
                    <a
                      data-toggle="modal"
                      data-target="#modalLoginImg"
                      className="gyan-link popup-zoom"
                      onClick={() =>
                        dispatch(
                          setModalDynamicImg(completefees && completefees)
                        )
                      }
                    >
                      {" "}
                      View Complete Course Fee
                    </a>
                  </p>
                </div>
              </div>
              <div
                id="offers"
                className={`uni_state_details ${CrStyle.university_education} d-flex flex-row align-items-center justify-content-around p-3`}
              >
                <div
                  className={`${CrStyle.university_location} calculte_emi  ${CrStyle["border-right"]} border-right`}
                >
                  <p>Calclulate your EMI</p>
                </div>
                <div
                  className={`${CrStyle.university_location}  ${CrStyle.EMI_btn} `}
                >
                  <p>
                    <a
                      className={`${CrStyle.btn} btn btn-large`}
                      data-toggle="modal"
                      data-target="#modalLoginForm2"
                    >
                      Get EMI Offer
                    </a>
                  </p>
                </div>
              </div>
              <div
                id="monthly-offer"
                className={`${CrStyle["uni_monthly-offer"]} uni_monthly-offer d-flex flex-row align-items-around justify-content-around mt-1`}
              >
                <a
                  className={`${CrStyle.btn} btn btn-large`}
                  data-toggle="modal"
                  data-target="#modalLoginForm2"
                >
                  Get July Offers
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalImg imgSrc={completefees}/>
    </>
  );
}
